// Utilities
import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useFirestore, useDocument } from "vuefire";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useUserStore } from "@/store/user";
import { useAnalytics } from "@/store/analytics";
import debounce from "lodash.debounce";
import dayjs from "dayjs";

export const useShopStore = defineStore("shop", () => {
  const app = getApp();
  const functions = getFunctions(app, "asia-northeast3");
  const callUpdateDisabledDates = httpsCallable(
    functions,
    "callUpdateDisabledDates",
  );
  const callUpdatePickupRange = httpsCallable(
    functions,
    "callUpdatePickupRange",
  );
  const callUpdateDisabledTimes = httpsCallable(
    functions,
    "callUpdateDisabledTimes",
  );
  const callSendInvitationData = httpsCallable(
    functions,
    "callSendInvitationData",
  );
  const callSubmitJdelingShopInfo = httpsCallable(
    functions,
    "callSubmitJdelingShopInfo",
  );
  const callSubmitShopInfoWithoutChat = httpsCallable(
    functions,
    "callSubmitShopInfoWithoutChat",
  );
  const callUpdateKakaoProfile = httpsCallable(
    functions,
    "callUpdateKakaoProfile",
  );

  const db = useFirestore();
  const analytics = useAnalytics();
  const userStore = useUserStore();
  const { isAdmin, isSuper } = storeToRefs(useUserStore());

  const shopId = ref();
  const loaded = ref(false);
  const aiAnalyzeChat = ref(false);
  const subscribe = ref(false);
  const sendSMS = ref(false);
  const sendOrderPickup = ref(false);
  const notifyNewReservation = ref(true);
  const trackSelected = ref(false);
  const usePayment = ref(true);
  const useDTR = ref(false);
  const useImage = ref(false);
  const useExpenses = ref(true);
  const hideDtrAlarm = ref(false);
  const showBotMessages = ref(false);
  const selectMethod = ref("");
  const isDemo = ref(false);
  const useSendReservation = ref(false);
  const useSendImages = ref(false);
  const chatListTolerance = ref(1);
  const useNewChatList = ref(false);
  const showAiMessages = ref(true);
  const cardInfo = ref("");
  const hideNoPayment = ref(false);
  const timeAdjustDisabled = ref(false);
  const defaultShowProps = ref("default");
  const sendAlimtalkWithSelfProfile = ref(false);
  const statusTags = ref([]);

  const isJdeling = computed(() => shopData.value?.group === "jdeling");

  const shopDoc = computed(() =>
    shopId.value
      ? doc(db, "shop", shopId.value).withConverter({
          fromFirestore: (snapshot) => {
            return snapshot.data();
          },
          toFirestore: (data) => data,
        })
      : undefined,
  );
  const shopData = useDocument(shopDoc);
  const orderFormFields = computed(
    () => shopData.value?.order_form_fields ?? [],
  );
  const dtrOrderFormShows = computed(
    () => shopData.value?.dtr_order_form_shows ?? [],
  );

  const hasJobQueue = computed(() => {
    return (shopData.value?.job_queue?.length ?? 0) > 0;
  });

  const noOrderForm = computed(
    () =>
      !shopData.value?.order_form_fields ||
      shopData.value?.order_form_fields.length == 0,
  );

  const shopDataId = computed(() => shopData.value?.id);

  watch(
    shopData,
    (newVal) => {
      if (!newVal) return;
      aiAnalyzeChat.value = newVal?.ai_analyze_chat ?? false;
      subscribe.value = newVal?.subscribe ?? false;
      sendSMS.value = newVal?.send_sms ?? false;
      sendOrderPickup.value = newVal?.send_order_pickup ?? false;
      notifyNewReservation.value = newVal.send_reservation_notification ?? true;

      trackSelected.value = newVal?.track_selected ?? false;
      usePayment.value = newVal?.use_payment ?? true;
      useDTR.value = newVal?.use_dtr ?? false;
      useImage.value = newVal?.use_image ?? false;
      useExpenses.value = newVal?.use_expenses ?? true;
      hideDtrAlarm.value = newVal?.hide_dtr_alarm ?? false;
      if (trackSelected.value) {
        selectMethod.value = newVal?.select_method ?? "";
        usePayment.value = false;
      }
      showBotMessages.value = newVal.chat_show_bot_messages ?? false;
      isDemo.value = (shopId.value != "_RvJxgxj" && newVal?.is_demo) ?? false;
      useSendReservation.value = newVal?.use_send_reservation ?? false;
      useSendImages.value = newVal?.use_send_images ?? false;
      chatListTolerance.value = newVal?.chat_list_tolerance ?? 1;
      useNewChatList.value = newVal.use_new_chat_list ?? false;
      cardInfo.value = newVal.card_info ?? "";
      showAiMessages.value = newVal.show_ai_messages ?? true;
      defaultShowProps.value = newVal.default_show_props ?? "default";
      hideNoPayment.value = newVal.hide_no_payment ?? false;
      timeAdjustDisabled.value = newVal.time_adjust_disabled ?? false;
      sendAlimtalkWithSelfProfile.value =
        newVal.send_alimtalk_with_self_profile;
      statusTags.value = newVal.status_tags ?? [
        { name: "제작 완료", key: "intermediate", color: "green-darken-3" },
        { name: "픽업 완료", key: "final", color: "green-darken-3" },
      ];
      userStore.assureLoaded().then(() => {
        if (!isAdmin.value) {
          analytics.setUserProperties({
            shop_name: newVal?.name_kr,
          });
        }
      });
      loaded.value = true;

      const updateLimit = dayjs().subtract(12, "h").valueOf();
      if (
        !newVal.profile_updated_at ||
        newVal.profile_updated_at.toMillis() < updateLimit
      ) {
        callUpdateKakaoProfile({ shopId: shopId.value });
      }
    },
    {
      immediate: true,
    },
  );

  watch(sendSMS, (newVal) => {
    if (newVal != shopData.value.send_sms) {
      updateDoc(doc(db, "shop", shopId.value), { send_sms: newVal });
    }
  });

  watch(sendOrderPickup, (newVal) => {
    if (newVal != shopData.value.send_order_pickup) {
      updateDoc(doc(db, "shop", shopId.value), { send_order_pickup: newVal });
    }
  });

  watch(subscribe, (newVal) => {
    if (newVal != shopData.value.subscribe) {
      updateDoc(doc(db, "shop", shopId.value), { subscribe: newVal });
    }
  });

  watch(aiAnalyzeChat, (newVal) => {
    if (newVal != shopData.value.ai_analyze_chat) {
      updateDoc(doc(db, "shop", shopId.value), { ai_analyze_chat: newVal });
    }
  });

  watch(notifyNewReservation, (newVal) => {
    if (newVal != shopData.value.send_reservation_notification) {
      updateDoc(doc(db, "shop", shopId.value), {
        send_reservation_notification: newVal,
      });
    }
  });

  watch(useDTR, (newVal) => {
    if (newVal != shopData.value.use_dtr) {
      updateDoc(doc(db, "shop", shopId.value), { use_dtr: newVal });
    }
  });

  watch(useImage, (newVal) => {
    if (newVal != shopData.value.use_image) {
      updateDoc(doc(db, "shop", shopId.value), { use_image: newVal });
    }
  });

  watch(useExpenses, (newVal) => {
    if (newVal != shopData.value.use_expenses) {
      updateDoc(doc(db, "shop", shopId.value), { use_expenses: newVal });
    }
  });

  watch(hideDtrAlarm, (newVal) => {
    if (newVal != shopData.value.hide_dtr_alarm) {
      updateDoc(doc(db, "shop", shopId.value), { hide_dtr_alarm: newVal });
    }
  });
  watch(useSendReservation, (newVal) => {
    if (newVal != shopData.value.use_send_reservation) {
      updateDoc(doc(db, "shop", shopId.value), {
        use_send_reservation: newVal,
      });
    }
  });

  watch(useSendImages, (newVal) => {
    if (newVal != shopData.value.use_send_images) {
      updateDoc(doc(db, "shop", shopId.value), {
        use_send_images: newVal,
      });
    }
  });

  watch(chatListTolerance, (newVal) => {
    if (newVal != shopData.value.chat_list_tolerance) {
      updateDoc(doc(db, "shop", shopId.value), {
        chat_list_tolerance: newVal,
      });
    }
  });

  watch(useNewChatList, (newVal) => {
    if (newVal != shopData.value.use_new_chat_list) {
      updateDoc(doc(db, "shop", shopId.value), {
        use_new_chat_list: newVal,
      });
    }
  });

  watch(hideNoPayment, (newVal) => {
    if (newVal != shopData.value.hide_no_payment) {
      updateDoc(doc(db, "shop", shopId.value), {
        hide_no_payment: newVal,
      });
    }
  });

  watch(showBotMessages, (newVal) => {
    if (newVal != shopData.value.chat_show_bot_messages) {
      updateDoc(doc(db, "shop", shopId.value), {
        chat_show_bot_messages: newVal,
      });
    }
  });

  watch(timeAdjustDisabled, (newVal) => {
    if (newVal !== shopData.value.time_adjust_disabled) {
      updateDoc(doc(db, "shop", shopId.value), {
        time_adjust_disabled: newVal,
      });
    }
  });
  watch(showAiMessages, (newVal) => {
    if (newVal != shopData.value.show_ai_messages) {
      updateDoc(doc(db, "shop", shopId.value), {
        show_ai_messages: newVal,
      });
    }
  });

  watch(trackSelected, (newVal) => {
    if (newVal != shopData.value.track_selected) {
      updateDoc(doc(db, "shop", shopId.value), {
        track_selected: newVal,
      });
    }
  });
  watch(sendAlimtalkWithSelfProfile, (newVal) => {
    if (newVal != shopData.value.send_alimtalk_with_self_profile) {
      updateDoc(doc(db, "shop", shopId.value), {
        send_alimtalk_with_self_profile: newVal,
      });
    }
  });
  function setShopId(_shopId) {
    if (shopId.value !== _shopId) {
      shopId.value = _shopId;
      analytics.setUserProperties({
        shop_id: _shopId,
      });
    }
  }

  function updateCustomFilter(customFilter) {
    if (shopId.value) {
      const updateVal = customFilter ? customFilter : deleteField();
      updateDoc(doc(db, "shop", shopId.value), {
        custom_filter: updateVal,
      });
    }
  }

  async function updateShopData(shopData) {
    await updateDoc(doc(db, "shop", shopId.value), shopData);
  }

  async function updateInvitationData(channelName) {
    return callSendInvitationData({ shopId: shopId.value, channelName });
  }

  async function assureLoaded() {
    while (!shopId.value || shopDataId.value !== shopId.value) {
      await new Promise((r) => setTimeout(r, 50));
    }
    return;
  }
  async function submitJdelingInfo(accountInfo, accountName) {
    await callSubmitJdelingShopInfo({
      shopId: shopId.value,
      accountInfo,
      accountName,
    });
  }
  async function submitShopInfo(
    shopName,
    channelUrl,
    accountInfo,
    accountName,
  ) {
    await callSubmitShopInfoWithoutChat({
      shopId: shopId.value,
      shopName,
      channelUrl,
      accountInfo,
      accountName,
    });
  }

  async function updateStatusTags(tags) {
    await updateDoc(doc(db, "shop", shopId.value), {
      status_tags: tags,
    });
  }

  return {
    assureLoaded,
    setShopId,
    shopData,
    aiAnalyzeChat,
    subscribe,
    sendSMS,
    sendOrderPickup,
    notifyNewReservation,
    updateCustomFilter,
    updateShopData,
    trackSelected,
    usePayment,
    selectMethod,
    useDTR,
    useImage,
    useExpenses,
    hideDtrAlarm,
    showBotMessages,
    shopId,
    isDemo,
    orderFormFields,
    dtrOrderFormShows,
    useSendReservation,
    useSendImages,
    chatListTolerance,
    useNewChatList,
    cardInfo,
    showAiMessages,
    callUpdateDisabledDates,
    callUpdatePickupRange,
    callUpdateDisabledTimes,
    hasJobQueue,
    noOrderForm,
    updateInvitationData,
    defaultShowProps,
    submitShopInfo,
    submitJdelingInfo,
    isJdeling,
    hideNoPayment,
    timeAdjustDisabled,
    sendAlimtalkWithSelfProfile,
    updateStatusTags,
    statusTags,
  };
});
